.dashboard-news-list {
    .headline {
        h1 {
            @apply font-extrabold text-24 text-white;
        }

        p {
            @apply font-bold text-11 text-white opacity-60;
        }
    }

    .horizontal-slider-container {
        > div {
            a div {
                @apply pr-7;
            }

            h2 {
                @apply text-stwBlue text-15 font-bold;
            }

            &:last-child {
                a div {
                    @apply pr-5;
                }
            }
        }
    }

    .stws-number,
    .stws-text,
    .progress-amount {
        @apply text-stwGray-dark font-medium text-11;
    }
}
