.product-detail-overview {
    p:not(.anormal-text) {
        @apply text-11 text-stwGray-dark opacity-50 font-medium;
    }

    .product-detail-stw-reward-counter {
        > span.stws {
            .stws-number,
            .stws-text {
                @apply text-stwBlue font-extrabold;
            }

            .stws-number {
                @apply text-20;
            }

            .stws-text {
                @apply text-11;
            }
        }
    }

    .product-detail-stw-bonus-counter {
        > span.stws {
            .stws-number,
            .stws-text {
                @apply text-primary font-extrabold;
            }

            .stws-number {
                @apply text-20;
            }

            .stws-text {
                @apply text-11;
            }
        }
    }
}
