@tailwind components;
@tailwind base;
@tailwind utilities;

@import 'fonts';
@import '~react-toastify/scss/main';

@layer base {
    body {
        max-width: 440px !important;
        margin: 0 auto !important;
        float: none !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
        @apply font-raleway;
    }

    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-3xl;
        @apply leading-5;
    }

    h3 {
        @apply text-2xl;
    }

    h4 {
        @apply text-xl;
    }

    img {
        max-width: unset;
    }

    .fixed {
        max-width: 440px !important;
    }
}

@layer components {
    input:focus ~ label.floating-label,
    input:not(:placeholder-shown) ~ label.floating-label,
    textarea:focus ~ label.floating-label,
    textarea:not(:placeholder-shown) ~ label.floating-label,
    select:focus ~ label.floating-label,
    select:not([value='']):valid ~ label.floating-label {
        @apply transform scale-75 -translate-y-8;
    }

    input:focus ~ label.floating-label,
    select:focus ~ label.floating-label {
        @apply text-stwBlue left-0;
    }
}
