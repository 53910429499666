.dashboard {
    .headline {
        h1 {
            @apply text-stwGray-dark font-extrabold text-24;
        }

        p {
            @apply text-stwGray-dark font-semibold text-11 opacity-50;
        }
    }
}
