.image-slider {
    -webkit-transform: translate3d(0, 0, 0);
}

.image-slider.slick-slider {
    .slick-dots {
        li.slick-active button:before {
            opacity: 1;
        }

        li button:before {
            color: white;
            opacity: 0.6;
            font-size: 8px;
        }
    }

    img {
        @apply w-full block;
    }
}
