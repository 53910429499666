.dashboard-user-overview {
    @apply pt-10 bg-stwGray-light rounded-bl-9xl p-10 relative;

    .stw-user-amount {
        .stws-number,
        .stws-text {
            @apply text-primary font-extrabold;
        }

        .stws-number {
            @apply text-40;
        }

        .stws-text {
            @apply text-15;
        }
    }

    .first-project-counter {
        p {
            @apply text-stwGray-dark opacity-70;
        }
    }
}
