.nav-active {
    @apply text-stwGray-dark opacity-100 font-semibold;

    .nav-icon-container {
        @apply border-primary border-2;

        svg {
            @apply text-stwGray-dark transform;
        }
    }

    &.profile-link {
        @apply text-primary;
    }
}

.dashboard-nav {
    @apply relative;

    &:after {
        content: '';
        background-image: url(../../../public/bg_top.svg);
        @apply bg-bottom bg-no-repeat bg-cover w-full h-40 absolute top-0 left-0 z-40;
    }
}
