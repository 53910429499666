.intro {
    @apply relative px-10 relative h-full;

    .slick-slider {
        @apply absolute w-full h-screen left-0 top-0;

        div {
            @apply h-screen;
        }

        .slick-slide {
            @apply relative;

            //&:before {
            //    content: '';
            //    @apply absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 z-10 h-screen;
            //}
        }

        .slick-dots {
            @apply top-8 bottom-auto;
        }

        img {
            @apply h-full;
        }
    }
}
