.product-detail {
    .slick-slider {
        .slick-dots {
            @apply bottom-44;
        }
    }

    .headline {
        h1 {
            @apply text-28 text-stwBlue font-extrabold leading-8;
        }

        p {
            @apply text-15 text-stwBlue font-normal mt-2;
        }
    }
}
