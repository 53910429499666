.product-detail-stws-support {
    .stw-user-amount {
        .stws-number,
        .stws-text {
            @apply text-primary font-extrabold;
        }

        .stws-number {
            @apply text-40;
        }

        .stws-text {
            @apply text-15;
        }
    }
}
