.project-detail-overview {
    p {
        @apply text-11 text-stwGray-dark opacity-50 font-medium;
    }

    .project-detail-stw-counter-invested {
        > span.stws {
            .stws-number,
            .stws-text {
                @apply text-primary font-extrabold;
            }

            .stws-number {
                @apply text-20;
            }

            .stws-text {
                @apply text-11;
            }
        }
    }
}
