.invest-button {
    @apply py-3 px-4 bg-green-200 hover:bg-green-200 focus:ring-green-300 focus:ring-offset-green-200 text-gray-600 w-full transition ease-in duration-200 text-center text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full disabled:opacity-60;

    .formatted-number {
        @apply font-bold;
    }
}

.confirm-button {
    @apply py-3 px-4 bg-stwBlue hover:bg-stwBlue text-white uppercase w-full transition ease-in duration-200 text-center text-xs shadow-md focus:outline-none rounded-full disabled:opacity-60 font-semibold;
}
