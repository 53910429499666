.digital-season-ticket {
    .headline {
        h1 {
            @apply text-28 font-extrabold;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: black;
    }

    .slick-prev {
        left: -30px !important;
    }

    .slick-next {
        right: -30px !important;
    }
}
