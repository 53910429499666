.products-list {
    .headline {
        h1 {
            @apply text-28 font-extrabold;
        }

        p {
            @apply font-semibold text-12 text-stwGray-dark opacity-60;
        }
    }
}
