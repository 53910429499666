.static-content {
    @apply break-words;

    .headline {
        h1 {
            @apply text-28 text-white font-extrabold leading-8;
        }

        p {
            @apply text-15 text-white font-normal mt-2;
        }
    }
}

.tab-content {
    max-height: 0;
    -webkit-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s;

    ul {
        list-style: unset !important;
        @apply my-3;
    }
}

.tab {
    input:checked {
        ~ .tab-content {
            max-height: 1000vh;
        }

        + label {
            font-size: 1.25rem;
            padding: 1.25rem;
            border-left-width: 2px;
            @apply bg-stwGray-light border-primary text-primary;
        }
    }

    label::after {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        float: right;
        right: 0;
        top: 0;
        bottom: 0;
        padding-right: 1.25rem;
        width: 1em;
        height: 100%;
        line-height: 1.5;
        font-size: 1.5rem;
        text-align: center;
        -webkit-transition: all 0.35s;
        -o-transition: all 0.35s;
        transition: all 0.35s;
    }

    input {
        &[type='checkbox'] + label::after {
            content: '+';
            font-weight: bold;
        }

        &[type='radio'] + label::after {
            content: '\25BE';
            font-weight: bold;
        }

        &[type='checkbox']:checked + label::after {
            transform: rotate(315deg);
            @apply text-primary;
        }

        &[type='radio']:checked + label::after {
            transform: rotateX(180deg);
            @apply text-primary;
        }
    }
}
